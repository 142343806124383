<template>
  <div class="login-container">
    <!-- <el-collapse accordion @change="selItem" v-model="name_collap"> -->
      <!-- <el-collapse-item name="profile"> -->
        <!-- <template #title><span class="buttxt">Личные данные</span></template> -->
        <div class="txt">Личные данные</div>
        <Udata mode="def" />
      <!-- </el-collapse-item> -->
    <!-- </el-collapse> -->
  </div>
</template>

<script>
import Udata from '../components/Udata'

export default {
  components: { Udata },
  data() {
    return {
      name_collap: "profile",
    }
  },
  methods: {
  },
}
</script>

<style  scoped>
.txt {
  font-size: 1rem;
  margin: 3%;
}
</style>